import { render, staticRenderFns } from "./SubMenuSide.vue?vue&type=template&id=369cd8b0&scoped=true&"
import script from "./SubMenuSide.vue?vue&type=script&lang=js&"
export * from "./SubMenuSide.vue?vue&type=script&lang=js&"
import style0 from "./SubMenuSide.vue?vue&type=style&index=0&id=369cd8b0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "369cd8b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TeamLogo: require('/builds/oho/oho-web-app/components/Team/Logo.vue').default,TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default,MemberProfileImage: require('/builds/oho/oho-web-app/components/Member/ProfileImage.vue').default})
