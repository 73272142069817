import _ from "lodash"

const getters = {
  app_platforms: (state, getters, rootState) => {
    return [
      {
        display_name: "Facebook Page",
        name: "facebook",
        icon: rootState.icon.facebook,
        limitation: {
          character: 2000,
          attachment_size: {
            image: 10 * Math.pow(10, 6),
            video: 10 * Math.pow(10, 6),
            file: 10 * Math.pow(10, 6),
          },
        },
      },
      {
        display_name: "LINE Official Account",
        name: "line",
        icon: rootState.icon.line,
        limitation: {
          character: 5000,
          attachment_size: {
            image: 10 * Math.pow(10, 6),
            video: 200 * Math.pow(10, 6),
            file: "not_support",
          },
        },
      },
      {
        display_name: "Instagram",
        name: "instagram",
        icon: rootState.icon.instagram,
        limitation: {
          character: 1000,
          attachment_size: {
            image: 8 * Math.pow(10, 6),
            video: "not_support",
            file: "not_support",
          },
        },
      },
      {
        display_name: "TikTok",
        name: "tiktok",
        icon: rootState.icon.tiktok,
        limitation: {
          character: 6000,
          attachment_size: {
            image: 3 * Math.pow(10, 6),
            video: "not_support",
            file: "not_support",
          },
        },
      },
    ]
  },
  contact_platforms: (state, getters, rootState) => {
    return [
      {
        display_name: "Messenger",
        name: "facebook",
        icon: rootState.icon.messenger,
      },
      {
        display_name: "Facebook Page",
        name: "facebook-page",
        icon: rootState.icon.facebook,
      },
      {
        display_name: "LINE OA",
        name: "line",
        icon: rootState.icon.line_app,
      },
      {
        display_name: "Instagram",
        name: "instagram",
        icon: rootState.icon.instagram,
      },
      {
        display_name: "TikTok",
        name: "tiktok",
        icon: rootState.icon.tiktok,
      },
    ]
  },
  getAppPlatform: (state, getters) => (platform) => {
    return _.find(getters.app_platforms, { name: platform })
  },
  getContactPlatform: (state, getters) => (platform) => {
    const res = _.find(getters.contact_platforms, { name: platform })
    return _.get(res, "display_name")
  },
  getAppPlatformIcon: (state, getters) => (platform) => {
    return _.chain(getters.app_platforms)
      .find({ name: platform })
      .get("icon")
      .value()
  },
  getContactPlatformIcon: (state, getters) => (platform) => {
    return _.chain(getters.contact_platforms)
      .find({ name: platform })
      .get("icon")
      .value()
  },
  getAppPlatformLimitatation: (state, getters) => (platform) => {
    return _.chain(getters.app_platforms)
      .find({ name: platform })
      .get("limitation")
      .value()
  },
}

export default { getters }
